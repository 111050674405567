import './App.css';
import { Box, createTheme, CssBaseline, Divider, ThemeProvider, useMediaQuery } from '@mui/material';
import TranscriptionsDataGrid from './components/TranscriptionsDataGrid';
import React, { useEffect } from 'react';
import BottomAppBar from './components/BottomAppBar';
import { useAudioPlayerContext } from './context/AudioPlayerContext'
import { useApiDataContext } from './context/ApiDataContext';
import { useStateContext } from './context/StateContext';
import TopBar from './components/TopBar';
import { usePrevious } from 'react-use';

export default function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const {
    playlist,
    setPlaylist,
    transcriptionRecordToTrack,
    currentTrack,
    setCurrentTrack,
    setTrackIndex
  } = useAudioPlayerContext();

  const {
    lastTranscription,
  } = useApiDataContext();

  const previousLastTranscription = usePrevious(lastTranscription);

  const {
    liveStream,
    isTranscriptionIncluded,
  } = useStateContext();

  useEffect(() => {
    if (lastTranscription && lastTranscription !== previousLastTranscription && liveStream && isTranscriptionIncluded(lastTranscription)) {
      const newTrack = transcriptionRecordToTrack(lastTranscription);
      const newPlaylist = [...playlist, newTrack];
      setPlaylist(newPlaylist);
      if (!currentTrack) {
        setCurrentTrack(newTrack)
        setTrackIndex(newPlaylist.length - 1)
      }
    }
  }, [
    lastTranscription,
    liveStream,
    playlist,
    currentTrack,
    previousLastTranscription,
    setPlaylist,
    setTrackIndex,
    transcriptionRecordToTrack,
    isTranscriptionIncluded,
    setCurrentTrack,
  ]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        <TopBar />
        <Divider />
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}            >
          <TranscriptionsDataGrid />
        </Box>
        <BottomAppBar />
      </div>
    </ThemeProvider>
  );
}