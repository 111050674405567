import {
    createContext,
    useContext,
    useState,
    ReactNode,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react';
import { RadioRecord, TalkgroupRecord, TalkgroupRequest, TimeRange, TranscriptionRecord, TranscriptionRequest } from '../client/generated';
import dayjs from 'dayjs';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { api } from '../client/Api';
import { AxiosError } from 'axios';
import ClientData, { RadioStatisticsMap, TalkgroupStatisticsMap } from '../ClientData';

interface MqttMessage {
    type: string,
    data: TranscriptionRecord
}

interface ApiDataContextType {
    transcriptions: TranscriptionRecord[];
    lastTranscription: TranscriptionRecord | undefined;
    talkgroups: TalkgroupRecord[];
    talkgroupMap: Map<number | null, TalkgroupRecord>;
    talkgroupStatistics: TalkgroupStatisticsMap;
    radios: RadioRecord[];
    radioMap: Map<number | null, RadioRecord>;
    radioStatistics: RadioStatisticsMap;
    beginTime: dayjs.Dayjs | undefined;
    setBeginTime: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>;
    endTime: dayjs.Dayjs | undefined;
    setEndTime: Dispatch<SetStateAction<dayjs.Dayjs | undefined>>;
}

const ApiDataContext = createContext<
    ApiDataContextType | undefined
>(undefined);

export const ApiDataProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [initialLoad, setInitialLoad] = useState(true)
    const [reloadData] = useState(0)
    const [transcriptions, setTranscriptions] = useState<TranscriptionRecord[]>([]);
    const [lastTranscription, setLastTranscription] = useState<TranscriptionRecord | undefined>(undefined);
    const [talkgroups, setTalkgroups] = useState<TalkgroupRecord[]>([]);
    const [talkgroupMap, setTalkgroupMap] = useState<Map<number | null, TalkgroupRecord>>(new Map());
    const [radios, setRadios] = useState<RadioRecord[]>([]);
    const [radioMap, setRadioMap] = useState<Map<number | null, RadioRecord>>(new Map());
    const [maxTranscriptionId, setMaxTranscriptionId] = useState(0);
    const [beginTime, setBeginTime] = useState<dayjs.Dayjs | undefined>(dayjs(Date.now()).subtract(2, 'day'));
    const [endTime, setEndTime] = useState<dayjs.Dayjs | undefined>(undefined);
    const [clientData] = useState<ClientData>(new ClientData());
    const { lastJsonMessage, readyState } = useWebSocket<MqttMessage>(`${process.env.REACT_APP_API_BASEURL}/ws`, {
        share: false,
        shouldReconnect: (closeEvent) => true,
    });
    const [webSocketPreviouslyConnected, setWebSocketPreviouslyConnected] = useState(false);

    const contextValue = {
        transcriptions,
        lastTranscription,
        talkgroupStatistics: clientData.talkgroupStatistics,
        talkgroups,
        talkgroupMap,
        radioStatistics: clientData.radioStatistics,
        radios,
        radioMap,
        beginTime,
        setBeginTime,
        endTime,
        setEndTime,
    };

    const fetchData = () => {
        Promise.all([
            api.talkgroups.getTalkgroupsTalkgroupsPost(
                {
                    requestBody:
                        {
                            time_range: {
                                min: beginTime ? beginTime.toISOString() : null,
                                max: endTime ? endTime.toISOString() : null,
                            } as TimeRange,
                        } as TalkgroupRequest
                }
            )
                .then((response) => {
                    const items = response.talkgroups ?? []
                    setTalkgroups(items);
                    const newTalkgroupMap = new Map(items.map(obj => [obj.talkgroup_id, obj]));
                    setTalkgroupMap(newTalkgroupMap);
                    return newTalkgroupMap;
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                }),
            api.radios.getRadiosRadiosPost(
                {
                    requestBody:
                        {
                            time_range: {
                                min: beginTime ? beginTime.toISOString() : null,
                                max: endTime ? endTime.toISOString() : null,
                            } as TimeRange,
                        } as TalkgroupRequest
                }
            )
                .then((response) => {
                    const items = response.radios ?? []
                    setRadios(items);
                    const newRadioMap = new Map(items.map(obj => [obj.radio_id as number | null, obj]));
                    setRadioMap(newRadioMap);
                    return newRadioMap;
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                }),
        ]).then(([newTalkgroupMap, newRadioMap]) => {
            api.transcriptions.getTranscriptionsTranscriptionsPost(
                {
                    requestBody: {
                        radio_ids: null,
                        talkgroup_ids: null,
                        limit: beginTime ? 100_000 : 500,
                        joined: false,
                        time_range: {
                            min: beginTime ? beginTime.toISOString() : null,
                            max: endTime ? endTime.toISOString() : null,
                        } as TimeRange,
                        transcription_range: {
                            min: maxTranscriptionId,
                        },
                    } as TranscriptionRequest
                })
                .then((response) => response.transcriptions ?? [])
                .then((newTranscriptions) => newTranscriptions.map(transcription => {
                    return {
                        ...transcription,
                        talkgroup_alias: newTalkgroupMap?.get(transcription.talkgroup_id)?.alias,
                        radio_name: newRadioMap?.get(transcription.radio_id)?.name,
                        logo: newRadioMap?.get(transcription.radio_id)?.logo ?? newTalkgroupMap?.get(transcription.talkgroup_id)?.logo,

                    } as TranscriptionRecord;
                }))
                .then((newTranscriptions) => {
                    clientData.updateStatistics(newTranscriptions, maxTranscriptionId > 0);
                    setTranscriptions(maxTranscriptionId > 0 ? newTranscriptions.concat(transcriptions) : newTranscriptions);

                    if (newTranscriptions.length > 0) {
                        const newMaxTranscriptionId = Math.max(...(newTranscriptions.map(x => x.transcription_id)));
                        setMaxTranscriptionId(newMaxTranscriptionId);
                    }
                })
                .catch((reason: AxiosError) => {
                    console.log(reason.message);
                })
        })
    }

    useEffect(() => {
        console.log(`ApiData Websocket State: ${readyState}`);
        if (readyState === ReadyState.OPEN) {
            if (webSocketPreviouslyConnected) {
                fetchData();
            }
            else {
                setWebSocketPreviouslyConnected(true);
            }
        }
    }, [readyState]);

    useEffect(() => {
        if (initialLoad || reloadData) {
            setInitialLoad(false)
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData, initialLoad]);

    const receiveSocketMessage = (message: MqttMessage) => {
        console.log(`WS Message Received ${JSON.stringify(message)}`);

        const transcription = {
            ...message.data,
            talkgroup_alias: talkgroupMap.get(message.data.talkgroup_id)?.alias,
            radio_name: radioMap.get(message.data.radio_id)?.name,
            logo: radioMap.get(message.data.radio_id)?.logo ?? talkgroupMap.get(message.data.talkgroup_id)?.logo,
        } as TranscriptionRecord;

        if (transcription.transcription_id > maxTranscriptionId) {
            setTranscriptions([transcription, ...transcriptions]);
            clientData.updateStatistics([transcription], true);
            setMaxTranscriptionId(transcription.transcription_id);
            setLastTranscription(transcription);
        } else {
            console.debug(`Already received transcription_id: ${transcription.transcription_id}`)
        }
    }

    useEffect(() => {
        if (lastJsonMessage) {
            receiveSocketMessage(lastJsonMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastJsonMessage]);

    return (
        <ApiDataContext.Provider value={contextValue}>
            {children}
        </ApiDataContext.Provider>
    );
};

export const useApiDataContext = (): ApiDataContextType => {
    const context = useContext(ApiDataContext);

    if (context === undefined) {
        throw new Error(
            'useApiDataContext must be used within an ApiDataProvider'
        );
    }

    return context;
};