import { TrackInfo } from './TrackInfo';
import { Controls } from './Controls';
// import { ProgressBar } from './ProgressBar';
import { Stack } from '@mui/material';

export const AudioPlayer = () => {
  return (
    <Stack direction='row'>
      <Stack direction='column'>
        <Controls />
        {/* <ProgressBar /> */}
      </Stack>
      <TrackInfo />
    </Stack>
  );
};