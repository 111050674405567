import { useCallback, useEffect, useRef, useState } from 'react';
import CellTowerIcon from '@mui/icons-material/CellTower';

import { useAudioPlayerContext } from '../../context/AudioPlayerContext';
import { IconButton, Stack } from '@mui/material';
import { MdFastForward, MdFastRewind, MdPause, MdPlayArrow, MdSkipNext, MdSkipPrevious } from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';
import { useStateContext } from '../../context/StateContext';

export const Controls = () => {
    const {
        currentTrack,
        audioRef,
        setDuration,
        duration,
        setTimeProgress,
        progressBarRef,
        trackIndex,
        setTrackIndex,
        setCurrentTrack,
        isPlaying,
        setIsPlaying,
        playlist: tracks,
    } = useAudioPlayerContext();
    const {
        liveStream,
        setLiveStream
    } = useStateContext();

    const [isReady,] = useState<boolean>(true);

    const playAnimationRef = useRef<number | null>(null);

    const updateProgress = useCallback(() => {
        if (audioRef.current && progressBarRef.current && duration) {
            const currentTime = audioRef.current.currentTime;
            setTimeProgress(currentTime);

            progressBarRef.current.value = currentTime.toString();
            progressBarRef.current.style.setProperty(
                '--range-progress',
                `${(currentTime / duration) * 100}%`
            );
        }
    }, [
        // duration,
        setTimeProgress,
        audioRef,
        progressBarRef
    ]);

    const startAnimation = useCallback(() => {
        if (audioRef.current && progressBarRef.current && duration) {
            const animate = () => {
                updateProgress();
                playAnimationRef.current = requestAnimationFrame(animate);
            };
            playAnimationRef.current = requestAnimationFrame(animate);
        }
    }, [updateProgress, duration, audioRef, progressBarRef]);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current?.play();
            startAnimation();
        } else {
            audioRef.current?.pause();
            if (playAnimationRef.current !== null) {
                cancelAnimationFrame(playAnimationRef.current);
                playAnimationRef.current = null;
            }
            updateProgress(); // Ensure progress is updated immediately when paused
        }

        return () => {
            if (playAnimationRef.current !== null) {
                cancelAnimationFrame(playAnimationRef.current);
            }
        };
    }, [isPlaying, startAnimation, updateProgress, audioRef]);

    const onLoadedMetadata = () => {
        const seconds = audioRef.current?.duration;
        // TEST. I think this keeps the data playing when stalled.
        audioRef.current?.play();
        if (seconds !== undefined) {
            setDuration(seconds);
            if (progressBarRef.current) {
                progressBarRef.current.max = seconds.toString();
            }
        }
    };

    const skipForward = () => {
        if (audioRef.current) {
            audioRef.current.currentTime += 15;
            updateProgress();
        }
    };

    const skipBackward = () => {
        if (audioRef.current) {
            audioRef.current.currentTime -= 15;
            updateProgress();
        }
    };

    const handlePrevious = useCallback(() => {
        setTrackIndex((prev) => {
            const newIndex = prev === 0
                ? tracks.length - 1
                : prev - 1;
            setCurrentTrack(tracks[newIndex]);
            return newIndex;
        });
    }, [setCurrentTrack, setTrackIndex, tracks]);

    const handleNext = useCallback(() => {
        setTrackIndex((prev) => {
            const newIndex = Math.min(prev + 1, tracks.length);
            setCurrentTrack(tracks[newIndex]);
            return newIndex;
        });
    }, [setCurrentTrack, setTrackIndex, tracks]);

    useEffect(() => {
        const currentAudioRef = audioRef.current;

        if (currentAudioRef) {
            currentAudioRef.onended = () => {
                handleNext(); // This function should handle both shuffle and non-shuffle scenarios
            };
        }

        return () => {
            if (currentAudioRef) {
                currentAudioRef.onended = null;
            }
        };
    }, [handleNext, audioRef]);

    return (
        <div className="flex gap-4 items-center">
            <audio
                // preload="none"
                src={currentTrack?.src}
                ref={audioRef}
                onLoadedMetadata={onLoadedMetadata}
            />
            <Stack direction='row'>
                <IconButton
                    onClick={handlePrevious}
                    disabled={trackIndex <= 0}
                    aria-label="go to previous"
                    color="secondary"
                >
                    <MdSkipPrevious size={24} />
                </IconButton>
                <IconButton
                    onClick={skipBackward}
                    // disabled={trackIndex <= 0}
                    aria-label="skip backwards"
                    color="secondary"
                >
                    <MdFastRewind size={24} />
                </IconButton>
                <IconButton
                    disabled={!isReady}
                    onClick={() => setIsPlaying((prev) => !prev)}
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                    size="large"
                    color={isPlaying ? 'success' : 'primary'}
                >
                    {!isReady && currentTrack ? (
                        <CgSpinner size={30} className="animate-spin" />
                    ) : isPlaying ? (
                        <MdPause size={30} />
                    ) : (
                        <MdPlayArrow size={30} />
                    )}
                </IconButton>
                <IconButton
                    onClick={skipForward}
                    // disabled={trackIndex >= tracks.length - 1}
                    aria-label="skip forwards"
                    color="secondary"
                >
                    <MdFastForward size={24} />
                </IconButton>
                <IconButton
                    onClick={handleNext}
                    disabled={trackIndex >= tracks.length - 1}
                    aria-label="go to next"
                    color="secondary"
                >
                    <MdSkipNext size={24} />
                </IconButton>
                <IconButton
                    color={liveStream ? 'success' : 'error'}
                    onClick={() => setLiveStream(!liveStream)}
                >
                    <CellTowerIcon />
                </IconButton>

            </Stack>

        </div>
    );
};