
import * as React from 'react';

import { Button, Modal, Box, Stack } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useApiDataContext } from '../context/ApiDataContext';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface TimeRangeModalProps {
    timeRangeModalOpen: boolean,
    setTimeRangeModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}


export default function TimeRangeModal(props: TimeRangeModalProps) {
    const handleClose = () => props.setTimeRangeModalOpen(false);
    const handleClearEndTime = () => setEndTime(undefined);

    const {
        beginTime,
        setBeginTime,
        endTime,
        setEndTime
    } = useApiDataContext();

    return (
            <Modal
                open={props.timeRangeModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack
                        direction="row"
                    >
                        <Stack
                            direction="column"
                        >
                            <DateTimePicker
                                label="Start Time"
                                defaultValue={beginTime}
                            />
                        </Stack>
                        <Stack
                            direction="column"
                        >
                            <DateTimePicker
                                label="End Time"
                                defaultValue={endTime}
                            />
                            <Button onClick={handleClearEndTime}>Clear</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
    );
}
