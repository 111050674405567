import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { RadioRecord, TalkgroupRecord } from "../client/generated";
import { Button, Modal, Box, Checkbox, Typography } from '@mui/material';
import { useStateContext } from '../context/StateContext';
import { useApiDataContext } from '../context/ApiDataContext';
import { FavoriteBorder, Favorite } from '@mui/icons-material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface RadiosModalParams {
    radiosModalOpen: boolean,
    setRadiosModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

function RadiosModal(params: RadiosModalParams) {
    const {
        selectedRadioIds,
        setSelectedRadioIds,
        selectedTalkgroupIds,
    } = useStateContext();
    const {
        radioStatistics,
        radioMap,
    } = useApiDataContext();
    const handleRadiosClose = () => params.setRadiosModalOpen(false);

    let tx_emoji = '\u{1F4AC}';
    let radio_emoji = '\u{1F399}';

    const handleClearRadios = () => {
        const newSelectedRadioIds = new Set<number>();
        setSelectedRadioIds(newSelectedRadioIds)
    }

    const toggleSelectedRadio = (value: number) => () => {
        const newSelectedRadioIds = new Set<number>(selectedRadioIds);
        newSelectedRadioIds.has(value) ? newSelectedRadioIds.delete(value) : newSelectedRadioIds.add(value);
        setSelectedRadioIds(newSelectedRadioIds);
    };

    function renderTalkgroupListItems(predicate?: (value: any, index: number, array: any[]) => unknown) {
        return (
            <React.Fragment>
                {
                    Array.from(radioStatistics)
                        .map(([radio_id, value]) => {
                            const radio = radioMap.get(radio_id);
                            return {
                                ...radio,
                                radio_id: radio_id,
                                tx_count: value.transcription_ids.size ?? 0,
                                talkgroup_count: value.talkgroup_ids.size ?? 0,
                                in_selected_talkgroup: value.talkgroup_ids.intersection(selectedTalkgroupIds).size > 0
                            }
                        })
                        .toSorted((a, b) => (a.tx_count ?? 0) < (b.tx_count ?? 0) ? -1 : (a.tx_count ?? 0) < (b.tx_count ?? 0) ? 1 : 0)
                        .filter(predicate ?? ((value) => true))
                        .reverse()
                        .map(function (radio) {
                            const labelId = `checkbox-list-secondary-label-${radio.radio_id}`;
                            return (
                                <ListItem
                                    key={radio.radio_id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={toggleSelectedRadio(radio.radio_id)}
                                            checked={selectedRadioIds.has(radio.radio_id)}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                    disablePadding
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={radio.name ?? ''}
                                            src={`${process.env.PUBLIC_URL}/${radio.logo}`}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        id={labelId}
                                        primary={`${radio.name} (${radio.radio_id})`}
                                        secondary={`${tx_emoji}${radio.tx_count} ${radio_emoji}${radio.talkgroup_count}`}
                                    />
                                </ListItem>
                            )
                        })
                }
            </React.Fragment>
        )

    }

    return (
        <Modal
            open={params.radiosModalOpen}
            onClose={handleRadiosClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...style,
                width: '100%',
                maxWidth: 500,
                // maxHeight: 300,
                // bgcolor: 'background.paper',
                // overflow: 'auto'
            }}>
                <Box sx={{
                    width: '100%',
                    // maxWidth: 360,
                    maxHeight: 500,
                    bgcolor: 'background.paper',
                    overflow: 'auto'
                }}>
                    <Typography variant='overline'>Selected</Typography>
                    <Button
                        onClick={handleClearRadios}
                        disabled={selectedRadioIds.size === 0}
                    >
                        {selectedRadioIds.size === 0 ? "Showing All Radios" : "Clear Selections"}
                    </Button>
                    <List>
                        {
                            renderTalkgroupListItems((value) => selectedRadioIds.has(value.radio_id))
                        }
                    </List>
                    <Typography variant='overline'>Selected</Typography>
                    <Button
                        onClick={handleClearRadios}
                        disabled={selectedRadioIds.size === 0}
                    >
                        {selectedRadioIds.size === 0 ? "Showing All Radios" : "Clear Selections"}
                    </Button>
                    <List>
                        {
                            renderTalkgroupListItems(
                                (value) => (
                                    !selectedRadioIds.has(value.radio_id)
                                    &&
                                    value.in_selected_talkgroup
                                )
                            )
                        }
                    </List>
                    <Typography variant='overline'>Available</Typography>
                    <List>
                        {
                            renderTalkgroupListItems(
                                (value) =>
                                    !(
                                        selectedRadioIds.has(value.radio_id)
                                        &&
                                        value.in_selected_talkgroup
                                    )
                            )
                        }
                    </List>
                </Box>
            </Box>
        </Modal>
    );
}

export default RadiosModal;
