import { useAudioPlayerContext } from '../../context/AudioPlayerContext';
import Avatar from '@mui/material/Avatar';
import { Box, Stack, Typography } from '@mui/material';

export const TrackInfo = () => {
    const { currentTrack } = useAudioPlayerContext();

    return (
        <Box sx={{ alignContent: 'center' }}>
            {
                currentTrack?.title &&
                <Stack direction='row'>
                    <Box sx={{ alignContent: 'center' }}>
                        <Avatar
                            alt={currentTrack?.title}
                            src={`${process.env.PUBLIC_URL}/${currentTrack?.thumbnail}`}
                        />
                    </Box>
                    <Box sx={{ alignContent: 'center' }}>
                        <Stack sx={{ alignContent: 'center' }} direction='column'>
                            <Typography>{currentTrack?.author ?? ''}</Typography>
                            <Typography>{currentTrack?.title ?? ''}</Typography>
                        </Stack>
                    </Box>
                </Stack>
            }
        </Box>
    );
};