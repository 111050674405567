import { Box, Stack } from '@mui/material';
import { AudioPlayer } from './audio-player-v2/AudioPlayer';

export default function TopBar() {
  return (
    <Box sx={{ zIndex: 'top' }} >
      <Stack direction='row'>
        <AudioPlayer />
      </Stack>
    </Box >
  )
}
